// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.nfl-card {
  display: flex;
}

.card-box {
  flex-grow: 1;
}

.vs {
  padding-top: 50%;
}

.line {
  align-self: start;
}

.line-favored {
  align-self: start;
}

.nfl-logo-container {
  height: 120px;
  display: flex;
}

.nfl-logo {
  margin: auto;
}

.form-select {
  margin: 0 auto;
  padding: 10px;
  min-width: 120px;
  max-width: 500px;
}

.underdog-winner {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    color: black;
  }

  70% {
    color: #ff7043;
  }

  100% {
    color: black;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;kFACgF;EAChF,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.nfl-card {\n  display: flex;\n}\n\n.card-box {\n  flex-grow: 1;\n}\n\n.vs {\n  padding-top: 50%;\n}\n\n.line {\n  align-self: start;\n}\n\n.line-favored {\n  align-self: start;\n}\n\n.nfl-logo-container {\n  height: 120px;\n  display: flex;\n}\n\n.nfl-logo {\n  margin: auto;\n}\n\n.form-select {\n  margin: 0 auto;\n  padding: 10px;\n  min-width: 120px;\n  max-width: 500px;\n}\n\n.underdog-winner {\n  animation: pulse-red 2s infinite;\n}\n\n@keyframes pulse-red {\n  0% {\n    color: black;\n  }\n\n  70% {\n    color: #ff7043;\n  }\n\n  100% {\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
